<template>
  <div :class="{ heap: labelPosition == 'left' }" @click="$refs.period.focus()">
    {{ label }}
    <br v-if="labelPosition != 'left'" />
    {{ labelPosition == "left" ? "&nbsp;" : null }}
    <select @change="$emit('change', $event.target.value)" ref="period">
      <option
        :value="{
          date_begin: dateFormatJS(date_begin),
          date_end: dateFormatJS(date_end),
          date_report: dateFormatJS(date_report),
        }"
      >
        {{ dateFormatVarious(this.date_begin) }} - {{ dateFormatVarious(this.date_end) }}
      </option>
    </select>
  </div>
</template>

<script>
import { dateFormatVarious, dateFormatJS } from "@/components-js/dateFormat";
import { ACCRUAL_PERIOD } from "@/config/settings";
export default {
  name: "DatePeriodSelector",
  props: {
    dateIn: { String, Date },
    label: String,
    labelPosition: String,
  },
  data() {
    return {
      dateLocal: null,
      date_begin: null,
      date_end: null,
      date_report: null,
      accrual_period: null,
    };
  },

  mounted() {
    this.dateLocal = this.dateIn;
    if (this.dateLocal == null) this.dateLocal = new Date();

    // date_begin, 16 or 1
    if (this.dateLocal.getDate() <= 15) {
      this.accrual_period = ACCRUAL_PERIOD[1];
      this.date_begin = new Date(this.dateLocal).setDate(16);
      this.date_begin = new Date(this.date_begin).setMonth(this.dateLocal.getMonth() - 1);
    } else {
      this.accrual_period = ACCRUAL_PERIOD[0];
      this.date_begin = new Date(this.dateLocal).setDate(1);
    }

    // date_end, 15 or last day of month
    if (new Date(this.date_begin).getDate() == 1)
      this.date_end = new Date(this.date_begin).setDate(15);
    else {
      this.date_end = new Date(this.dateLocal).setDate(1);
      this.date_end = new Date(this.date_end).setDate(new Date(this.date_end).getDate() - 1);
    }

    // date_report, next date after date_end (end of calculation period)
    this.date_report = new Date(this.date_end).setDate(new Date(this.date_end).getDate() + 1);

    this.$emit("change", {
      date_begin: dateFormatJS(this.date_begin),
      date_end: dateFormatJS(this.date_end),
      date_report: dateFormatJS(this.date_report),
      accrual_period: this.accrual_period,
    });
  },

  methods: {
    dateFormatVarious(d, t) {
      return dateFormatVarious(d, t);
    },

    dateFormatJS(d) {
      return dateFormatJS(d);
    },
  },
};
</script>
